import React       from 'react';
import { graphql } from 'gatsby';
import { Box }     from '@chakra-ui/react';

import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import HeaderMedia  from '@interness/theme-default/src/components/HeaderMedia';
import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Lightbox     from '@interness/web-core/src/components/media/Lightbox/Lightbox';
import Center       from '@interness/web-core/src/components/structure/Center/Center';
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';


const FassadePage = (props) => {
  return (
    <>
      <HeaderMedia id="header-fassade"/>
      <Spacer/>
      <Wrapper>
        <Heading subtitle="Optimaler Schutz">Fassade</Heading>
        <Box textAlign="justify">
          <p>Das Dach mit Fassade schützt Ihr Haus vor Witterungseinflüssen und spart Heizkosten mit einer
            wirkungsvollen
            Dämmung. Sie ist das Gesicht Ihres Hauses, prägt sein äußeres Erscheinungsbild. Fassaden müssen viel
            aushalten: Regen, Wind, Frost und Hitze. Durch die Temperaturunterschiede können Spannungen in den
            Baustoffen
            entstehen. Im schlimmsten Fall gibt es Risse im Mauerwerk. Wärmeverluste an Gebäuden müssen ständig durch
            Energiezufuhr ausgeglichen werden - das ist teuer. Weil Wohnqualität zugleich Lebensqualität ist, kommen
            Wärmedämmung und Feuchtigkeitsschutz beim Fassadenbau große Bedeutung zu.
          </p>
        </Box>
        <Heading tag="h2">Übersicht Fassaden</Heading>
        <Lightbox images={props.data.gallery.images} thumbnails={props.data.gallery.thumbnails} fromApi/>
        <Spacer/>
        <Center>
          <CallToAction/>
        </Center>
      </Wrapper>
      <Spacer/>
    </>
  )
};

export default FassadePage;

export const query = graphql`
    query {
        gallery: directusMediaCollection(name: {eq: "fassade-gallery"}) {
            ...LightboxGalleryGrid
        }
    }
`;